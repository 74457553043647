<template>
  <v-app style="min-height: 0%; margin-bottom: 0; z-index: 1000;">
  <div class="navbar navbar-fixed" :class="{ 'navbar-reduced': navbarReduced }" style="min-height: 0%; margin-bottom: 0;">
    <v-row>
      <v-col cols="12" sm="3" lg="1"></v-col>
      <v-col cols="12" sm="6" lg="3" class="row-center" >
          <a href="/">
            <img src="../assets/Logo.png" alt="Logo" :class="{ 'logo-hidden': navbarReduced }" style="width: 300px" >
          </a>
      </v-col>
      <v-col cols="12" sm="3" lg="1"></v-col>
      <v-col cols="12" sm="12" lg="7">
        <div class="btn-container">
          <button  class="btn-sidebar" @click="btn_clicked = true"><v-icon>mdi-menu</v-icon> menu  </button>
        </div>
        <div class="routerbar">
          <router-link to="/About">Notre mission</router-link>
          <v-menu offset-y v-model="menuOpen1" @mouseleave="closeMenu1">
            <template v-slot:activator="{ on }">
              <a href="#" v-on="on" @mouseover="openMenu1" >Produits</a>
            </template>
            <v-list>
              <router-link to="/biotag/medical">
                <v-list-item>Biotag Médical</v-list-item>
              </router-link>
              <router-link to="/biotag/performance">
                <v-list-item>Biotag Performance</v-list-item>
              </router-link>
              <router-link to="/biotag/plateforme">
                <v-list-item>Implant Plateforme</v-list-item>
              </router-link>
              <router-link to="/boitier/convergence">
                <v-list-item>Solution Convergence</v-list-item>
              </router-link>
            </v-list>
          </v-menu>
          <v-menu offset-y>
            <template v-slot:activator="{ on }">
              <a href="#" v-on="on">Accès client</a>
            </template>
            <v-list>
              <router-link to="/connexion">
                <v-list-item>Se connecter</v-list-item>
              </router-link>
              <router-link to="/logiciel/health-cloud">
                <v-list-item>Découvrir Biotag Cloud</v-list-item>
              </router-link>
            </v-list>
          </v-menu>
          <router-link to="/contact">contact</router-link>
        </div>
      </v-col>
    </v-row>
  </div>
  <v-navigation-drawer  light permanent rounded fixed style="z-index:1001; background-color: var(--grisclair); " class="show-sidebar" v-if="btn_clicked">
		<v-list-item>
			<v-list-item-icon>
				<v-icon>mdi-menu</v-icon>
			</v-list-item-icon>
			<v-list-item-title class="title">
				Menu
			</v-list-item-title>
		</v-list-item>
		<v-divider></v-divider>
		<v-list>
			<v-list-item 
				v-for="item in items" 
				:key="item.title" 
				:to="item.route" 
				link
        @click="btn_clicked = false"
			>
				<v-list-item-icon>
					<v-icon>{{ item.icon }}</v-icon>
				</v-list-item-icon>
				<v-list-item-content>
					<v-list-item-title>{{ item.title }}</v-list-item-title>
				</v-list-item-content>
			</v-list-item>
		</v-list>
    </v-navigation-drawer>
</v-app>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      menuOpen: false,
      menuOpen1 : false,
      menuOpen2 : false,
      btn_clicked : false,
      navbarReduced: false,
      items: [
                    {
						title: 'Accueil',
						route: '/'
					},
					{
						title: 'Biotag Médical',
						route: '/biotag/medical'
					},
					{
						title: 'Biotag Performance',
						route: '/biotag/performance',
					},
					{
						title: 'Implant Plateforme',
						route: '/biotag/plateforme',
					},
                    {
						title: 'Solution Convergence',
						route: '/boitier/convergence',
					},
					{
						title: 'Notre Mission',
						route: '/About'
					},
					{
						title: 'Découvrir Biotag Cloud',
						route: '/logiciel/health-cloud'
					},
					{
						title: 'Contact',
						route: '/contact'
					},
				],
				right: null,
    };
  },
  methods: {
    openMenu() {
      this.menuOpen = true;
    },
    openMenu1() {
      this.menuOpen1 = true;
    },
    openMenu2() {
      this.menuOpen2 = true;
    },
    closeMenu() {
      this.menuOpen = false;
      console.log('heeiçehiofbr')
    },
    closeMenu1() {
      this.menuOpen1 = false;
      console.log('heeiçehiofbr')
    },
    closeMenu2() {
      this.menuOpen2 = false;
      console.log('heeiçehiofbr')
    },
    handleScroll() {
      this.navbarReduced = window.scrollY > 0;
    },
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
};
</script>

<style lang="scss">

.v-application--wrap {
  min-height: auto !important;
}

.routerbar a{
  color: var(--bleu) !important;
}

.theme--light.v-application {
    background-color: var(--grisclair) !important; 
}

.navbar{
  border-bottom: solid 1px var(--vertfonce);
}

.navbar-fixed{
  position: fixed;
  top: 0;
  left: 0;
  right: 0; /* Choisissez la valeur de z-index en fonction de votre mise en page */
  background-color: var(--grisclair); /* Choisissez la couleur de fond de votre barre de navigation */
  /* Ajoutez d'autres styles nécessaires à votre barre de navigation */
}

.routerbar{
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-top: 30px;
}

.routerbar a{
  font-family: 'Roboto', sans-serif, medium;
  font-size: 18px;
  text-transform: uppercase;
  color: var(--vertfonce) !important;
  text-decoration: solid;
}

/* Style pour le dropdown */
.v-list-item {
  cursor: pointer;
}

.v-list-item:hover {
  background-color: #f5f5f5;
}

.v-list-item__title {
  font-family: roboto, medium;
  font-size: 16px;
}

.v-menu__content {
  border-radius: 0;
  border: solid 1px var(--vertfonce);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.3);
  margin-top: 5px;
  
}

.v-menu__content .v-list {
  padding: 0;
}

.v-menu__content .v-list-item {
  min-height: 40px;
  padding: 5px 16px;
}



.btn-container{
  display: none;
}

.show-sidebar {
  transition: opacity 0.3s ease-in-out;
  opacity: 1;
}

@media (max-width: 1700px) {
  
  .routerbar a{
    font-size: 16px
  }
}

@media (max-width: 1264px) {

  
  .routerbar{
    margin-top: 0;
    padding: 0 2%;
  }

  .routerbar a{
    font-size: 14px
  }
}

@media (max-width: 767px) {
  .logo-hidden{
    display: none;
  }
  .navbar-reduced {
    height: 50px; 
  }
  .routerbar{
    display: none;
  }
  

  .routerbar a{
    font-size: 14px
  }

  .btn-sidebar{
    border: solid 3px var(--vertfonce);
    font-family: Roboto, Regular;
    font-size: 17px;
    font-weight: 450;
    color: var(--vertfonce);
    background-color: white;
    padding: 8px 25px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    position: relative;
    text-decoration:none;
    border-radius: 25px;
  }

  .btn-container{
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .theme--light v-icon{
    color: var(--vertfonce) !important;
  }
}

@import '~vuetify/dist/vuetify.min.css';
</style>