import Vue from 'vue'
import App from './App.vue'

import router from './router'
import store from './store'

import BackEnd from './back-end'
import Vuetify from 'vuetify'

import Toasted from 'vue-toasted'

Vue.use(Vuetify)
Vue.prototype.$eventHub = new Vue();
Vue.prototype.$api = new BackEnd();

Vue.config.productionTip = false;
Vue.use(Toasted);


new Vue({
	router,
	store,
	vuetify: new Vuetify(),
	render: h => h(App)
}).$mount('#app')