<template>
	<v-app style="background-color: var(--grisclair);" class="my-app">
		<nav-bar v-if="$route.name !== 'code-access'"/>
		<router-view style="margin-top:90px;"/>
	</v-app>
</template>

<script>
  import NavBar from './components/NavBar'
  export default {
    name: 'App',
    components: {
      NavBar,
    },
    data() {
      return {
        showSidebar: false,
        showSidebarAdmin: false,
        showNavBar: false,
        showFooter: false,
        showNavBarCloud: false,
        hideBar: false,
      }
    },
    methods: {
      updateRouteState(to) {
        // Vérifier si la page actuelle nécessite l'affichage de la Sidebar
        if (
          (to.name && (to.name === 'tableau-de-bord' || to.name === 'Courbes Real time' || to.name === 'Courbes Récent' || to.name === 'export Biometric data' || to.name === 'export csv max30101' || to.name === 'export csv max30101' || to.name === 'export csv hub' || to.name === 'my account')) ||
          this.$route.path === '/home' // Ajouter cette condition pour la page d'accueil
        ) {
          // Afficher la navbar et le footer
          this.showSidebar = true;
        } else {
          // Cacher la sidebar et le footer
          this.showSidebar = false;
        }

        if (
          (to.name && (to.name === 'creation activation' || to.name === 'tag activation' || to.name === 'liste des utilisateurs' || to.name === 'liste des tags' || to.name === 'creation token')) ||
          this.$route.path === '/home' // Ajouter cette condition pour la page d'accueil
        ) {
          // Afficher la navbar et le footer
          this.showSidebarAdmin = true;
        } else {
          // Cacher la sidebar et le footer
          this.showSidebarAdmin = false;
        }

        if(to.name === 'code-access'){
					this.hideBar = true
				}else{
					this.hideBar = false
				}
      }
    },
    created() {
      // Initialiser l'état de l'application
      
      this.updateRouteState(this.$route);
      // Écouter l'événement route pour mettre à jour l'état de l'application
      this.$router.beforeEach((to, from, next) => {
        this.updateRouteState(to);
        next();
      });

      // Écouter l'événement route pour mettre à jour l'état de l'application
      this.$router.onReady(() => {
        this.$nextTick(() => {
          this.$router.afterEach((to, from) => {
            this.updateRouteState(to);
          });
        });
      });
    },
  }
</script>

<style>

html{
	font-size: 19px;
}

:root{
/* couleurs sites */
    --violet: #545479;
    --vert: #02c39a;
    --vertfonce: #007889;
    --grisclair: #EDF3FC;
    --vertgris: #BDDADE;
}

.big-title{
	font-family: 'Roboto', sans-serif;
    font-size: 4rem;
    color: white;
}

.margin-navbar{
	margin-top: 80px;
}

.body-container {
    min-height: calc(100vh - 200px); /* Ajustez la valeur (200px) en fonction de la hauteur de votre footer */
}

.footer-container {
    min-height: calc(100vh - 200px);
}

.width5{
    padding: 0 5%;
}
.mt50{
  margin-top: 50px;
}
.mt100{
  margin-top: 100px;
}

.mb100{
  margin-bottom: 100px;
}
.green-title{
	font-family: 'Roboto', sans-serif;
  font-size: 3rem;
  color: var(--vertfonce);
	margin-bottom: 20px;
}

.baton{
    width: 60%;
    height: 2px;
    background-color: white;
    margin-bottom: 40px;
}

.text-classi{
	font-family: 'Roboto', sans-serif;
  font-size: 1rem;
  color: var(--violet);
	text-align: justify;
}

.text-blue{
	text-align: center;
  color: var(--vertfonce);
  font-family: 'Roboto', sans-serif;
	font-size: 1.2rem;
}

.text-white{
	font-family: 'Roboto', sans-serif;
  font-size: 1.1rem;
  color: white;
}

.row-center{
	display: flex;
	justify-content: center;
}

.row-between{
	display: flex;
	justify-content: space-between;
}

.row-around{
	display: flex;
	justify-content: space-around;
}


body{
  background-color: var(--grisclair);
}

.my-app {
  min-height: 0;
}

#btn{
    border: solid 2px var(--vertfonce);
    background-color: var(--vertfonce);
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    color: white;
    padding: 11px 32px 11px 33px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    text-decoration:none;
	  border-radius: 10px;
}

#btn:hover{
    background-position: 100%;
    color: var(--vertfonce);
    background-color: transparent;
}

#btn-2{
    border: solid 2px var(--vertfonce);
    background-color: var(--vertfonce);
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #FFF;
    padding: 8px 20px;
    background-repeat: no-repeat;
    background-position: 0%;
    -webkit-transition: background 300ms ease-in-out;
    transition: background 300ms ease-in-out;
    position: relative;
    text-decoration:none;
    border-radius: 25px;
}
#btn-2:hover{
    background-position: 100%;
    color: var(--vertfonce);
    background-color: transparent;
}

.data-mesurées{
    width: 30%;
    background-color: var(--vertgris);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
    border-radius: 20px;
}

.data-mesurées h3{
    color: var(--violet);
    font-family: 'Roboto', sans-serif;
    font-size: 25px;
    margin-bottom: 20px;
    margin-top: 20px;
    text-transform: uppercase;
    font-weight: 600;
}

.data-mesurées div{
    display: flex;
    justify-content: center;
}

.applications{
    display: flex;
    justify-content: space-between;
}

.applications div{
    width: 30%;
    margin-top: 50px;
    margin-bottom: 20px;
    background-color: white;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 20px;
    max-width: 100%; 
    overflow: hidden;
}

.applications div img{
      width: 100%;
    border: solid 1px var(--bleu);
    transition: transform 0.3s ease-in-out;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
     /* ajouter une transition fluide */
}

.applications div img:hover{
    transform: scale(1.2); /* agrandir l'image de 20% */
}

.applications div p{
    margin-top: 16px;
    margin-bottom: 50px;
    margin-left: 10px;
    margin-right: 10px;
    text-align: justify;
}

.applications div h3{
    font-family: 'Roboto', sans-serif;
    font-size: 20px;
    color: var(--vertfonce);
    margin-top: 25px;
    margin-left: 10px;
}

.trais-v{
    width: 80%;
    background-color: var(--violet);
    height: 2px;
}

.colonnes{
    display: flex;
    justify-content: space-around;
}


li img{
  width: 18%;
}

.colonnes li:hover{
    font-weight: 600;
}

.text{
    display: flex;
    justify-content: space-around;
}

.text div{
    display: flex; 
    align-items: left; 
    text-align: left;
    width: 85%;   
}

.text li{
    margin-top: 10px; 
    font-family: 'roboto' sans-serif;
    font-size: 1rem;
}

.btn-loc{
  width: 100%; 
  display: flex; 
  justify-content: right; 
  margin-top: 50px;
}

@media (max-width: 1264px) {

.big-title{
  text-align: left;
  font-size: 2rem;
}

.pd5{
  padding: 0 5%;
}
.row-between{
  display: block;
}
.data-mesurées{
  padding: 4% 1%;
  margin-top: 30px;
}
.colonnes{
    display: block;
}
.green-title{
  font-size: 1.7rem;
	line-height: 1.7rem;
	text-align: left;
}



.text-white{
	font-size: 0.8rem;
}

.margin-navbar{
	margin-top: 125px;
}

.text-classi{
	font-size: 0.9rem;
	line-height: 1.1rem;
	font-weight: 400;
}
}

@media (max-width: 767px) {

.mt100{
    margin-top: 25px;
}

.margin-navbar{
	margin-top: 140px;
}

.green-title{
	text-align: left;
}

.position-resp{
    width: 100%;
	padding: 0 5%;
}
}
</style>